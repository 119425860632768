.logo {
  margin-top: 5px;
  height: 40px;
}

.MuiToolbar-root {
  min-height: 56px !important;
  height: 56px !important;
}

.header-subtitle .title {
  font-size: 14px;
  color: #666;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.header-subtitle .subtitle {
  font-size: 14px;
  color: #666;
  text-align: center;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
